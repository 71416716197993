$body-bg: #0C0C0F;
$body-color: #0C0C0F;
$dark: #0C0C0F;
$warning: #EBA700;

// TODO: enable only the used animations
// https://github.com/tgdev/animate-sass#animation-module-loading
$use-all: true; // enable all animations defined by animate-sass

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/animate-sass/animate";

@include media-breakpoint-up(xs) {
  html {
    font-size: 0.9rem!important;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem!important;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.1rem!important;
  }
}

html {
  width: 100%;
  height: 100%;
}

html {
  font-size: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: $body-bg;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

#root {
    height: 100%;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.btn {
    border-radius: 0;
}

.form-control {
    border-radius: 0;
}
