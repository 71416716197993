.terminalContainer {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 5px;
    padding-bottom: 0px;
}

.terminalOutput > div {
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
